.item {
    text-align: center;
}

.img {
    width: 130px;
}

.name {
    font-size: 16px;
    margin: 8px 0;
}

.price {
    font-weight: 700;
    font-family: 'LucidaGrande', serif;
}

.btn {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    background: rgb(248, 168, 24);
    border-radius: 10px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 700;
    transition: background .2s linear;
}

.btn:hover {
    background: lightgreen;
}